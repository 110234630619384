html {
  font-size: 1vw;
  height: 100%;
}

img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 1920px) and (max-height: 1080px) {
  html {
    font-size: 18px; /* 设定一个适当的字体大小 */
  }
}
