.articleWrap {
  max-width: 1000px;
}

.articleWrap .posts-list .post-item {
  margin-bottom: 28px;
  background: #f7f7f7;
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.articleWrap .posts-list .post-item:hover {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}

.articleWrap .posts-list .post-item .post-img {
  width: 190px;
  height: 190px;
  margin: 20px;
}

.articleWrap .posts-list .post-item .post-img img {
  width: 100%;
  height: 100%;
  transition: all 0.3s ease-out 0s;
}

.articleWrap .posts-list .post-item:hover .post-img img {
  transform: scale(1.1, 1.1);
}

.articleWrap .posts-list .post-item .articleMain {
  flex: 1;
  margin: 22px 20px 20px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}


.articleWrap .posts-list .post-item .articleMain .articleMainTitleWrap {
  display: flex;
  flex-direction: column;
}
